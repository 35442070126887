import { Bed, MapPinned, Plane, Plus, TentTree } from 'lucide-react'
import { CardType } from './types'

interface Props {
  className?: string
  type?: CardType
}

export const CardPreviewPlaceholder = ({ className, type }: Props) => {
  switch (type) {
    case CardType.Flight:
      return <Plane className={className} />
    case CardType.Accommodation:
      return <Bed className={className} />
    case CardType.Place:
      return <MapPinned className={className} />
    case CardType.Add:
      return <Plus className={className} />
    default:
      return <TentTree className={className} />
  }
}
