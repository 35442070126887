import { type MouseEvent } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { LazyImage } from '../ui/image'
import { CardPreviewPlaceholder } from './card-preview-placeholder'
import { type CardType } from './types'

interface Props {
  className?: string
  type?: CardType
  photoSrc?: string | null
  onClick?: (e: MouseEvent) => void
}

export const CardPreviewImage = ({
  className,
  type,
  photoSrc,
  onClick,
}: Props) => {
  if (photoSrc != null) {
    return (
      <LazyImage
        className="rounded-xl object-cover"
        src={photoSrc}
        alt="Itinerary card preview image"
        onClick={onClick}
      />
    )
  }
  return (
    <div
      className={cn(
        'bg-husthere-disabled flex items-center justify-center rounded-xl text-[#424242]',
        className,
      )}
      onClick={onClick}
    >
      <CardPreviewPlaceholder className="h-28 w-28" type={type} />
    </div>
  )
}

const thumbnailRoundedCorner = ['tl', 'tr', 'bl', 'br']

export const CardPreviewGallery = ({
  className,
  thumbnailPhotos,
  onClick,
}: {
  className?: string
  thumbnailPhotos: string[]
  onClick?: (e: MouseEvent) => void
}) => {
  return (
    <div className={cn('grid grid-cols-2', className)} onClick={onClick}>
      {thumbnailPhotos.slice(0, 4).map((photoSrc, i) => (
        <LazyImage
          key={`itinerary-thumbnail-${i}`}
          className={cn(
            'h-full w-full object-cover',
            `rounded-${thumbnailRoundedCorner[i]}-2xl`,
          )}
          src={photoSrc}
          alt="Itinerary card preview image"
        />
      ))}
    </div>
  )
}
